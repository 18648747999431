<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable max-len -->
<template>
  <footer class="footer bg-light">
    <div class="container py-4">
      <div class="row">
        <!-- Left Side -->
        <div class="col-md-4 mb-4 mb-md-0">
          <img
            src="../../assets/wp/logo.png"
            alt="Logo"
            class="img-fluid mb-3"
            style="width: 200px"
          />
          <p class="text-start">
            Somos a Associação dos Servidores da Educação dos Municípios e do Estado de
            Goiás, formada por profissionais conhecedores da realidade e, acima de tudo,
            preocupados em oferecer aos servidores públicos um espaço inovador para
            atender às demandas individuais e coletivas da categoria.
          </p>
        </div>

        <!-- Middle Side -->
        <div class="col-md-4 mb-4 mb-md-0">
          <h5 class="text-center fw-bold mb-3">
            Associação dos Servidores da Educação dos Municípios e do Estado de Goiás
          </h5>
          <div class="mb-3">
            <p class="text-start mb-1">CNPJ: 44.303.029/0001-18</p>
            <p class="text-start mb-1">Rua 88, 547 Qd. F-35 Lt. 59</p>
            <p class="text-start mb-1">Setor Sul, Goiânia-GO</p>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex mb-2">
              <a
                href="https://www.instagram.com/asemego.oficial/"
                target="_blank"
                class="text-decoration-none text-primary me-4"
              >
                <i class="bi bi-instagram me-2"></i>Instagram
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=556236246665&amp;text&amp;type=phone_number&amp;app_absent=0"
                target="_blank"
                class="text-decoration-none text-primary"
              >
                <i class="bi bi-whatsapp me-2"></i>Whatsapp
              </a>
            </div>
            <div class="d-flex">
              <a
                href="https://www.facebook.com/people/Asemego-Goi%C3%A1s/100071729289200/"
                target="_blank"
                class="text-decoration-none text-primary me-4"
              >
                <i class="bi bi-facebook me-2"></i>Facebook
              </a>
              <a
                href="https://www.linkedin.com/in/asemego-goi%C3%A1s-a8649821a/?originalSubdomain=br"
                target="_blank"
                class="text-decoration-none text-primary"
              >
                <i class="bi bi-linkedin me-2"></i>Linkedin
              </a>
            </div>
          </div>
        </div>

        <!-- Right Side -->
        <div class="col-md-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15286.734604284242!2d-49.2478679!3d-16.6927066!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef15103c4b0a1%3A0x317fe1ab0a8f97cc!2sASEMEGO!5e0!3m2!1spt-BR!2sbr!4v1681796775308!5m2!1spt-BR!2sbr"
            width="100%"
            height="250"
            style="border: 2px solid var(--primaria)"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          >
          </iframe>
        </div>
      </div>
    </div>

    <!-- Bottom Side -->
    <div class="rodape-asemego text-white py-2">
      <div class="container text-center">
        <span class="fw-bold"
          >ASEMEGO © {{ getAnoAtual() }} - Todos os direitos reservados</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    getAnoAtual() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
a {
  color: var(--primaria) !important;
}

.rodape-asemego {
  background-color: var(--primaria);
}

footer a:hover {
  color: var(--bs-secondary) !important;
}
</style>
