<template>
  <div class="session-bar w-100 d-flex py-2 px-2 justify-content-between">
    <router-link to="/schedules" class="btn btn-secondary">
        <i class="bi bi-arrow-left"></i>
      </router-link>
    <h2 class="fw-bold text-center">{{ schedule.nome }}</h2>
    <div></div>
  </div>
  <div class="container">

    <!-- Tag do Agendamento -->
    <div class="d-flex justify-content-center my-3">
      <div class="card-tag px-3 py-2 fw-bold">
        <span class="me-2">Categoria:</span>
        <span v-if="schedule.tipo == 'others'">Outros</span>
        <span v-else-if="schedule.tipo == 'health'">Saúde e Bem-estar</span>
        <span v-else-if="schedule.tipo == 'room'">Salas</span>
        <span v-else>Espaços</span>
      </div>
    </div>

    <!-- Corpo do Componente -->
    <div class="container-body">
      <!-- Imagens -->
      <div class="row justify-content-center g-3">
        <div class="col-12 col-md-6 text-center" v-if="this.schedule.imagem">
          <img
            class="img-fluid rounded shadow"
            :src="getImage(schedule.id)"
            alt="Imagem 1"
          />
        </div>
        <div class="col-12 col-md-6 text-center" v-if="this.schedule.imagem_2">
          <img
            class="img-fluid rounded shadow"
            :src="getImage2(schedule.id)"
            alt="Imagem 2"
          />
        </div>
      </div>

      <!-- Descrição -->
      <div v-if="schedule.descricao != null" class="row justify-content-center my-4">
        <div class="col-12 col-md-8">
          <div class="text-start fw-bold p-3">
            <span>{{ schedule.descricao }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- Botões de Contato -->
  <div class="session-bar row justify-content-center py-2">
        <div class="col-12 col-md-auto mb-2 mb-lg-0">
          <a
            v-if="schedule.telefone !== null"
            class="btn btn-info w-100"
            :href="schedule.telefone"
            target="_blank"
          >
            <i class="bi bi-telephone-fill me-1"></i>
            Telefone
          </a>
        </div>
        <div class="col-12 col-md-auto">
          <a
            v-if="schedule.whatsapp !== null"
            class="btn btn-success w-100"
            :href="schedule.whatsapp"
            target="_blank"
          >
            <i class="bi bi-telephone me-1"></i>
            Whatsapp
          </a>
        </div>
      </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      schedule: {},
    };
  },
  beforeCreate() {
    axios
      .get(`https://api.asemego.com.br/api/agendamentos/${this.id}`)
      .then((res) => {
        this.schedule = res.data.agendamento;
      })
      .catch(() => {});
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    getImage(id) {
      return `https://api.asemego.com.br/api/agendamentos/${id}/imagem?number=1`;
    },
    getImage2(id) {
      return `https://api.asemego.com.br/api/agendamentos/${id}/imagem?number=2`;
    },
  },
};
</script>

<style scoped>
/* Estilos mínimos necessários */

.session-bar {
  background-color: #333342;
  color: #FFF;
}

.card-tag {
  width: fit-content;
}

.img-fluid {
  max-height: 300px;
}
</style>
