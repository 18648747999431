<!-- eslint-disable max-len -->
<template>
<div class="header-component">
  <div class="d-flex flex-row w-100 bar-desktop py-1 label-navbar">
      <div class="container text-start">
        <span class="mx-5">
          <i class="bi bi-telephone"></i>
          (62) 3624-6665
        </span>
        <span>
          <i class="bi bi-envelope"></i>
          contato@asemego.com.br
        </span>
      </div>
  </div>
  <nav class="navbar navbar-expand-lg shadow-sm bg-white rounded">
    <div class="container">
      <a  href="/">
        <img class="mx-5 brand-logo" src="../../assets/wp/logo.png">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarAsemego" aria-controls="navbarAsemego" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarAsemego">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link"  href="/">HOME</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  href="/about">SOBRE</a>
          </li>
          <li class="nav-item d-flex flex-col text-center justify-content-center">
            <a
              href="https://asemegovantagens.com.br/portal/cadastro-clubes?id_clube=104&page=convenios"
              class="nav-link nav-link--club"
              target="_blank"
            >
              VANTAGENS
            </a>
          </li>
          <li class="nav-item">
            <a  href="/news" class="nav-link">NOVIDADES</a>
          </li>
          <li class="nav-item">
            <a  href="/partners" class="nav-link">CONVÊNIOS</a>
          </li>
          <li class="nav-item">
            <a  href="/schedules" class="nav-link">AGENDAMENTOS</a>
          </li>
          <li v-if='!logged' class="nav-item button-nav-mobile button-login-mobile">
            <a  href="/login" class="nav-link">
              <i class="bi bi-person-fill"></i>
              LOGIN
            </a>
          </li>
          <li v-if='!logged' class="nav-item button-nav-mobile button-plan-mobile">
            <a  href="/plans" class="nav-link">
              <i class="bi bi-bag-fill"></i>
              PLANOS
            </a>
          </li>
          <li v-if='logged && isAssociated' class="nav-item button-nav-mobile button-plan-mobile">
            <a  href="/account/info" class="nav-link" style="color: white">
              <i class="bi bi-person-video2"></i>
              PAINEL
            </a>
          </li>
          <li v-if='logged && isAdmintrator' class="nav-item button-nav-mobile button-plan-mobile">
            <a  href="/dashboard/overall" class="nav-link" style="color: white">
              <i class="bi bi-person-video2"></i>
              DASHBOARD
            </a>
          </li>
          <li v-if='logged' class="nav-item button-nav-mobile bg-danger">
            <a class="nav-link" style="color: white" @click="logout">
              <i class="bi bi-arrow-left-circle"></i>
              SAIR
            </a>
          </li>
        </ul>
      </div>
      <div v-if='logged && isAssociated' class="bar-desktop d-flex flex-row">
        <a class="btn btn-outline-success"  href="/account/info">
          <i class="bi bi-person-video2"></i>
          Painel
        </a>
        <button v-if='logged' type="button" class="btn btn-outline-danger ms-2" @click="logout">Sair</button>
      </div>
      <div v-if='logged && isAdmintrator' class="bar-desktop d-flex flex-row">
        <a class="btn btn-outline-success"  href="/dashboard/overall">
          <i class="bi bi-person-video2"></i>
          Dashboard
        </a>
        <button v-if='logged' type="button" class="btn btn-outline-danger ms-2" @click="logout">Sair</button>
      </div>
      <div v-if='!logged' class="bar-desktop d-flex flex-row">
        <a class="btn btn-success me-3"  href="/login">
          <i class="bi bi-person-fill"></i>
          Login
        </a>
        <a class="btn btn-outline-secondary"  href="/plans">
          <i class="bi bi-bag-fill"></i>
          Planos
        </a>
      </div>
    </div>
  </nav>
</div>
<Loading v-if="isLoading" />
</template>

<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';
import { useAuthStore } from '../../store/store';

export default {
  components: { Loading },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    logged() {
      return useAuthStore().logged;
    },
    isAssociated() {
      return useAuthStore().isUser;
    },
    isAdmintrator() {
      return useAuthStore().isAdmin;
    },
  },

  mounted() {
    // Verifique o estado de autenticação no carregamento inicial da página
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      const role = localStorage.getItem('role');
      const authStore = useAuthStore();
      authStore.setLogged(true);

      // Defina as roles com base nas informações armazenadas no localStorage
      if (role === '56751') {
        authStore.setAdmin(true);
      } else if (role === '1') {
        authStore.setUser(true);
      }
    }
  },

  methods: {
    logout() {
      this.isLoading = true;
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      axios
        .post('https://api.asemego.com.br/api/logout')
        .then(() => {
          localStorage.removeItem('login');
          localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('id_vindi');
          localStorage.removeItem('role');
          localStorage.removeItem('nome');
          localStorage.removeItem('carteirinha');
          sessionStorage.removeItem('userData');
          const authStore = useAuthStore();
          authStore.setLogged(false);
          authStore.setAdmin(false);
          authStore.setUser(false);
          this.$router.push('/');
        })
        .catch(() => {
          localStorage.removeItem('login');
          localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('id_vindi');
          localStorage.removeItem('role');
          localStorage.removeItem('nome');
          localStorage.removeItem('carteirinha');
          sessionStorage.removeItem('userData');
          const authStore = useAuthStore();
          authStore.setLogged(false);
          authStore.setAdmin(false);
          authStore.setUser(false);
          this.$router.push('/');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>

.label-navbar {
  background-color: rgba(51, 51, 66, 1);
  color: #FFF;
}
.nav-link--club {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.button-nav-mobile {
  display: none !important;
}

li {
  font-weight: bold;
}

li:hover {
  text-decoration: underline;
}

.nav-link {
  font-size: 12px;
}

.brand-logo {
    width: 120px; height: auto
}

@media screen and (min-width: 1399px) {
  .brand-logo {
    width: 150px; height: auto
  }

}

@media screen and (min-width: 1199px) {
  .brand-logo {
    width: 120px; height: auto
  }

}


@media screen and (max-width: 991px) {
  .bar-desktop {
    display: none !important;
  }
  .button-nav-mobile {
    display: block !important;
  }

  .button-login-mobile {
    background-color: lightgreen;
  }

  .button-plan-mobile {
    background-color: lightseagreen;
  }
}
</style>
