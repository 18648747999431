<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
  <div class="schedules-view mb-5">
    <h2 class="my-4 fw-bold" v-if="this.stepPage == '1' || this.stepPage == 1">
      Agendamentos disponíveis:
    </h2>
    <div class="session-bar w-100 d-flex py-2 px-2 justify-content-between" v-if="this.stepPage > 1">
      <button class="btn btn-secondary" type="button" @click="backToSelect"><i class="bi bi-arrow-left w-100"></i></button>
      <h2 class="fw-bold text-center">{{ this.sessionSelected }}</h2>
      <div></div>
    </div>
    <div class="container">
      <div
        class="schedules-choices row"
        v-if="this.stepPage == '1' || this.stepPage == 1"
      >
        <div class="col-12 mb-4">
          <div class="card card-choice" @click="selectTerapia">
            <img src="../assets/terapia.jpeg" class="card-image" />
            <div class="card-img-overlay">
              <span class="card-body title-card">Terapias</span>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="card card-choice" @click="selectSala">
            <img src="../assets/salas.jpg" class="card-image" />
            <div class="card-img-overlay">
              <span class="card-body title-card">Salas</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-choice" @click="selectEspaco">
            <img src="../assets/espacos.png" class="card-image" />
            <div class="card-img-overlay">
              <span class="card-body title-card">Espaços</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5" v-if="this.stepPage == 2 && this.sessionSelected == 'Terapias'">
        <div
          class="img-partner col-6 col-md-4"
          v-for="schedule in healthSchedules"
          :key="schedule.id"
        >
          <router-link :to="{ name: 'SchedulesDetails', params: { id: schedule.id } }">
            <div class="card-convenio">
              <img :src="convertLinkLogo(schedule.id)" />
            </div>
          </router-link>
          <span class="fw-bold">{{ schedule.nome }}</span>
        </div>
      </div>
      <div class="row my-5" v-if="this.stepPage == 2 && this.sessionSelected == 'Salas'">
        <div
          class="img-partner col-6 col-md-4"
          v-for="schedule in roomSchedules"
          :key="schedule.id"
        >
          <router-link :to="{ name: 'SchedulesDetails', params: { id: schedule.id } }">
            <div class="card-convenio">
              <img :src="convertLinkLogo(schedule.id)" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="row my-5" v-if="this.stepPage == 2 && this.sessionSelected == 'Espaços'">
        <div
          class="img-partner col-6 col-md-4"
          v-for="schedule in placesSchedules"
          :key="schedule.id"
        >
          <router-link :to="{ name: 'SchedulesDetails', params: { id: schedule.id } }">
            <div class="card-convenio">
              <img :src="convertLinkLogo(schedule.id)" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<!-- eslint-disable max-len -->
<!-- eslint-disable global-require -->
<script>
import axios from "axios";
import Loading from "../components/molecules/Loading.vue";

export default {
  data() {
    return {
      schedules: [],
      healthSchedules: [],
      roomSchedules: [],
      placesSchedules: [],
      url: "https://asemego.com.br/api/storage/app/",
      isLoading: false,
      stepPage: 1,
      sessionSelected: null,
    };
  },
  components: { Loading },
  created() {
    this.getAllTypes();
  },
  methods: {
    convertLinkLogo(id) {
      const link = `https://api.asemego.com.br/api/agendamentos/${id}/imagem`;
      return link;
    },
    selectTerapia () {
      this.stepPage = 2;
      this.sessionSelected = 'Terapias';
    },
    selectSala () {
      this.stepPage = 2;
      this.sessionSelected = 'Salas';
    },
    selectEspaco () {
      this.stepPage = 2;
      this.sessionSelected = 'Espaços';
    },
    backToSelect () {
      this.stepPage = 1;
      this.sessionSelected = null;
    },
    getAllTypes() {
      this.isLoading = true;
      axios
        .get("https://api.asemego.com.br/api/agendamentos")
        .then((res) => {
          this.schedules = res.data.agendamentos;
          const healthSchedules = this.schedules.filter(
            (schedule) => schedule.tipo === "health"
          );
          this.healthSchedules = healthSchedules;
          const roomSchedules = this.schedules.filter(
            (schedule) => schedule.tipo === "room"
          );
          this.roomSchedules = roomSchedules;
          const placesSchedules = this.schedules.filter(
            (schedule) => schedule.tipo === "place"
          );
          this.placesSchedules = placesSchedules;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style scoped>
.session-bar {
  background-color: #333342;
  color: #FFF;
}

img {
  height: 200px;
  width: 300px;
  border-radius: 6px;
  z-index: 1;
  -webkit-box-shadow: 0px 1px 11px 5px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 1px 11px 5px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 1px 11px 5px rgba(0, 0, 0, 0.36);
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
}

.img-partner {
  margin-bottom: 1rem !important;
}

.img-large {
  width: 300px !important;
}
.schedules-view {
  height: 100dvh !important;
}
.card {
  border: none !important;
}
.card-image {
  width: 100% !important;
  opacity: 0.2;
}

.card-convenio:hover {
  opacity: 1 !important;
}
.card-img-overlay > span {
  font-size: 100px;
}
.card-choice:hover, .card-choice:active, .card-convenio:hover, .card-convenio:active {
  cursor: pointer;
}
.card-choice {
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .schedules-view {
    height: 100% !important;
  }
  .schedules-view .img-partner {
    margin-bottom: 1rem !important;
  }
  .schedules-view img {
    height: 120px;
    width: 150px;
    opacity: 1 !important;
  }
  .schedules-view .img-large {
    height: 100px !important;
    width: 120px !important;
  }
  .schedules-view .card-image {
    opacity: 0.2 !important;
    width: 100% !important;
  }
  .schedules-view .card-img-overlay > span {
    font-size: 60px;
  }
  .schedules-view .btn-mbl {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
