<template>
  <div class="convenio-details-view bg-white">
    <div class="session-bar w-100 d-flex py-2 px-2 justify-content-between">
      <router-link to="/partners" class="btn btn-secondary">
          <i class="bi bi-arrow-left"></i>
        </router-link>
      <h2 class="fw-bold text-center mb-0">Convênios</h2>
      <div></div>
    </div>
    <div class="container" v-if="!isLoading">

      <!-- Informações do Parceiro -->
      <div class="card-partner-informations">
        <div class="container">
          <!-- Tag do Parceiro -->
          <div class="d-flex justify-content-center my-3">
            <div class="card-tag px-3 py-2 bg-dark text-white rounded fw-bold">
              <span v-if="partner.tag == 'others'">Outros</span>
              <span v-else-if="partner.tag == 'health'">Saúde e Bem-estar</span>
              <span v-else-if="partner.tag == 'tech'">Tecnologia</span>
              <span v-else-if="partner.tag == 'industry'">Indústria</span>
              <span v-else-if="partner.tag == 'creativity'">Criatividade</span>
              <span v-else-if="partner.tag == 'education'">Educação</span>
              <span v-else-if="partner.tag == 'business'">Negócios e Finanças</span>
              <span v-else-if="partner.tag == 'entertainment'"
                >Artes e Entretenimento</span
              >
              <span v-else-if="partner.tag == 'Sustainability'">Sustentabilidade</span>
              <span v-else-if="partner.tag == 'food'">Alimentação e Gastronomia</span>
              <span v-else-if="partner.tag == 'tourism'">Viagens e Turismo</span>
              <span v-else>Categoria nova</span>
            </div>
          </div>

          <div class="container-body">
            <!-- Logo do Parceiro -->
            <div class="text-center">
              <img
                class="img-fluid rounded shadow my-2"
                :src="convertLinkLogo(partner.id)"
                alt="Logo do Parceiro"
              />
            </div>

            <!-- Nome e Descrição do Parceiro -->
            <div class="text-center my-4">
              <p class="fw-bold">{{ partner.nome }}</p>
              <p v-if="partner.descricao" class="descripton-partner text-center mx-auto">
                {{ partner.descricao }}
              </p>
            </div>

            <!-- Botões de Contato -->
            <div class="row justify-content-center my-5 g-2">
              <div v-if="partner.telefone" class="col-12 col-md-auto">
                <a
                  class="btn btn-secondary w-100"
                  :href="partner.telefone"
                  target="_blank"
                >
                  <i class="bi bi-telephone-fill me-1"></i>
                  Telefone
                </a>
              </div>
              <div v-if="partner.telefone_2" class="col-12 col-md-auto">
                <a
                  class="btn btn-secondary w-100"
                  :href="partner.telefone_2"
                  target="_blank"
                >
                  <i class="bi bi-telephone-fill me-1"></i>
                  Telefone
                </a>
              </div>
              <div v-if="partner.whatsapp" class="col-12 col-md-auto">
                <a class="btn btn-success w-100" :href="partner.whatsapp" target="_blank">
                  <i class="bi bi-telephone me-1"></i>
                  Whatsapp
                </a>
              </div>
              <div v-if="partner.whatsapp_2" class="col-12 col-md-auto">
                <a
                  class="btn btn-success w-100"
                  :href="partner.whatsapp_2"
                  target="_blank"
                >
                  <i class="bi bi-telephone me-1"></i>
                  Whatsapp
                </a>
              </div>
              <div v-if="partner.youtube" class="col-12 col-md-auto">
                <a class="btn btn-danger w-100" :href="partner.youtube" target="_blank">
                  <i class="bi bi-youtube me-1"></i>
                  Youtube
                </a>
              </div>
              <div v-if="partner.facebook" class="col-12 col-md-auto">
                <a class="btn btn-primary w-100" :href="partner.facebook" target="_blank">
                  <i class="bi bi-facebook me-1"></i>
                  Facebook
                </a>
              </div>
              <div v-if="partner.instagram" class="col-12 col-md-auto">
                <a
                  class="btn btn-instagram w-100"
                  :href="partner.instagram"
                  target="_blank"
                >
                  <i class="bi bi-instagram me-1"></i>
                  Instagram
                </a>
              </div>
              <div v-if="partner.instagram_2" class="col-12 col-md-auto">
                <a
                  class="btn btn-instagram w-100"
                  :href="partner.instagram_2"
                  target="_blank"
                >
                  <i class="bi bi-instagram me-1"></i>
                  Instagram
                </a>
              </div>
              <div v-if="partner.website" class="col-12 col-md-auto">
                <a
                  class="btn btn-secondary w-100"
                  :href="partner.website"
                  target="_blank"
                >
                  <i class="bi bi-globe me-1"></i>
                  Website
                </a>
              </div>
            </div>

            <!-- Promoções -->
            <div v-if="partner.promocoes" class="row justify-content-center my-3">
              <div class="col-12 col-md-8">
                <div class="card card-partner bg-dark text-white p-3 rounded shadow">
                  <div class="d-flex align-items-center">
                    <img
                      class="card-img-partner me-3"
                      src="../assets/voucher.png"
                      alt="Voucher"
                    />
                    <span class="fw-bold">{{ partner.promocoes }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Imagens Adicionais -->
            <div class="row justify-content-center my-5">
              <div v-if="partner.imagem_1" class="col-12 text-center">
                <img
                  class="rounded shadow my-2"
                  :src="convertLinkImage1(partner.id)"
                  alt="Imagem 1"
                />
              </div>
              <div v-if="partner.imagem_2" class="col-12 text-center">
                <img
                  class="rounded shadow my-2"
                  :src="convertLinkImage2(partner.id)"
                  alt="Imagem 2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container w-100 py-5 d-flex justify-content-center" v-if="isLoading">
      <Loading style="max-width: 100px;" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/atoms/Loading.vue";

export default {
  data() {
    return {
      partner: {
        imagens: [],
      },
      isLoading: false,
    };
  },
  components: { Loading },
  props: {
    partnerId: {
      type: String,
      required: true,
    },
  },
  beforeCreate() {
    this.isLoading = true;
    axios
      .get(`https://api.asemego.com.br/api/parceiros/${this.partnerId}`)
      .then((res) => {
        this.partner = res.data;
      })
      .catch(() => {})
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    convertLinkLogo(id) {
      return `https://api.asemego.com.br/api/parceiros/${id}/logo`;
    },
    convertLinkImage1(id) {
      return `https://api.asemego.com.br/api/parceiros/${id}/imagem?imagem=1`;
    },
    convertLinkImage2(id) {
      return `https://api.asemego.com.br/api/parceiros/${id}/imagem?imagem=2`;
    },
  },
};
</script>

<style scoped>
/* Estilos mínimos necessários */

.session-bar {
  background-color: #333342;
  color: #FFF;
}


.btn-instagram {
  background-color: red;
  color: #FFF;
}

.btn-instagram:hover {
  background-color: darkred;
}

.card-tag {
  width: fit-content;
}

.card-img-partner {
  width: 40px;
  height: 40px;
}

.img-fluid {
  max-height: 300px;
}
</style>
